import Head from "next/head";
import { getPageTitle } from "../helpers/page";
import { View } from "../components/View/View";
import { BasicLayout } from "../components/Layout/Layout";
import { ErrorContent } from "../components/ErrorView";

const errorTitle = "学習可能期間外のためご利用いただけません。";

function CourseOutOfDurationErrorPage() {
  return (
    <BasicLayout>
      <CourseOutOfDurationErrorView />
    </BasicLayout>
  );
}

export function CourseOutOfDurationErrorView() {
  return (
    <>
      <Head>
        <title>{getPageTitle(errorTitle)}</title>
      </Head>
      <View colorType="color-gray-lighten-3">
        <ErrorContent
          title={errorTitle}
          description={
            <>
              指定のコースは現在学習可能期間外のためご利用いただけません。
              <br />
              配信元の教室に学習コンテンツ配信の配信状況をご確認ください。
            </>
          }
        />
      </View>
    </>
  );
}

export default CourseOutOfDurationErrorPage;
