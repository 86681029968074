import { createContext, useContext, useEffect, useState } from "react";
import { HTTPError } from "../../helpers/api";
import { ErrorView } from "./ErrorView";

type ErrorState = ReturnType<typeof useInitHttpErrorState>;

const Context = createContext<ErrorState | undefined>(undefined);

export const useInitHttpErrorState = () => {
  return useState<HTTPError | null>(null);
};

type Props = {
  children: React.ReactNode;
};
export const HTTPErrorProvider = (props: Props) => {
  return (
    <Context.Provider value={useInitHttpErrorState()}>
      <ErrorComponent>{props.children}</ErrorComponent>
    </Context.Provider>
  );
};

const ErrorComponent = (props: Props) => {
  const [error] = useContext(Context) as ErrorState;

  if (error) {
    return <ErrorView error={error} />;
  }
  return <>{props.children}</>;
};

export const useHttpError = (error?: HTTPError | null) => {
  const [, setErrorState] = useContext(Context) as ErrorState;
  useEffect(() => {
    if (error) {
      setErrorState(error);
    }
  }, [error]);

  return {
    dispatchErrorPage: setErrorState,
  };
};
