import { stringify } from "query-string";

const boronUrl = process.env.BORON_URL;
const apiUrl = (path: string) => `${boronUrl}${path}`;
export const PER_PAGE = 20;

interface RequestOptions {
  signal?: AbortSignal;
}

export interface GetRequestOptions extends RequestOptions {
  query?: string | { [key: string]: any };
}

interface AbortControllerWrapper {
  path: string;
  abortController: AbortController;
}

const requestInitBase: RequestInit = {
  mode: "cors",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
  credentials: "include",
};

const requestInitBaseWithJSONContentType: RequestInit = {
  ...requestInitBase,
  headers: {
    ...requestInitBase.headers,
    "Content-Type": "application/json; charset=utf-8",
  },
};

class ApiClient {
  abortControllerStore: AbortControllerWrapper[];

  constructor() {
    this.abortControllerStore = [];
  }

  get(path: string, options?: GetRequestOptions): Promise<Response> {
    let url = path;

    const requestInit: RequestInit = {
      ...requestInitBaseWithJSONContentType,
      method: "GET",
    };

    if (!options) {
      return fetch(apiUrl(url), requestInit);
    }

    if (options.query) {
      if (typeof options.query === "object") {
        url = `${url}?${stringify(options.query, { arrayFormat: "bracket" })}`;
      } else if (typeof options.query === "string") {
        url = `${url}${options.query}`;
      }
    }
    if (options.signal) {
      requestInit.signal = options.signal;
    }
    return fetch(apiUrl(url), requestInit);
  }

  post(path: string, body?: Record<string, any>) {
    const requestInit: RequestInit = {
      ...requestInitBaseWithJSONContentType,
      method: "POST",
    };

    if (body) {
      requestInit.body = JSON.stringify(body);
    }

    return fetch(apiUrl(path), requestInit);
  }

  patch(path: string, body?: Record<string, any>) {
    const requestInit: RequestInit = {
      ...requestInitBaseWithJSONContentType,
      method: "PATCH",
    };

    if (body) {
      requestInit.body = JSON.stringify(body);
    }

    return fetch(apiUrl(path), requestInit);
  }

  put(path: string, body?: Record<string, any>) {
    const requestInit: RequestInit = {
      ...requestInitBaseWithJSONContentType,
      method: "PUT",
    };

    if (body) {
      requestInit.body = JSON.stringify(body);
    }

    return fetch(apiUrl(path), requestInit);
  }

  delete(path: string, body?: Record<string, any>) {
    const requestInit: RequestInit = {
      ...requestInitBaseWithJSONContentType,
      method: "DELETE",
    };

    if (body) {
      requestInit.body = JSON.stringify(body);
    }

    return fetch(apiUrl(path), requestInit);
  }

  sendFormData(path: string, method: string, values: any) {
    const formData = this.makeFormData(values);
    const requestInit: RequestInit = {
      ...requestInitBase,
      method,
      body: formData,
    };

    return fetch(apiUrl(path), requestInit);
  }

  interruptGet(path: string, options?: GetRequestOptions) {
    const current = this.abortPreviousRequest(path);
    return this.get(path, {
      ...options,
      signal: current.abortController.signal,
    });
  }

  abortPreviousRequest(path: string): AbortControllerWrapper {
    const previous = this.abortControllerStore.find((a) => a.path === path);

    if (previous) {
      previous.abortController.abort();
      previous.abortController = new AbortController();
      return previous;
    } else {
      const current = {
        path,
        abortController: new AbortController(),
      };

      this.abortControllerStore.push(current);
      return current;
    }
  }

  // Record<string, any> の値をFormDataで送信可能な形に変換する
  // 例:
  // values = {
  //   name: "name value",
  //   file: File,
  //   units: [
  //     {
  //     name: "foo",
  //       contents: [
  //         {
  //           name: "hoge",
  //         }
  //       ]
  //     },
  //   ]
  // }
  // makeFormData(values)
  // -> FormData {
  //      "name": "name value"
  //      "file": File,
  //      "units[0][name]": "foo
  //      "units[0][contents][0][name]": "hoge"
  //    }
  private makeFormData(
    values: Record<string, any>,
    formData_?: FormData,
    parentKey?: string,
  ): FormData {
    const formData = formData_ || new FormData();

    // 最初の呼び出しは各フィールドに対してkeyをつけてmakeFormDataを呼ぶ
    if (!parentKey) {
      Object.keys(values).forEach((key) => {
        this.makeFormData(values[key], formData, key);
      });
    }

    // プロパティを掘る必要がないものはそのままappendする
    else if (
      // instance
      values instanceof Date ||
      values instanceof File ||
      values instanceof Blob ||
      // primitive
      typeof values === "string" ||
      typeof values === "number" ||
      typeof values === "boolean" ||
      typeof values === "symbol" ||
      typeof values === "bigint" ||
      typeof values === "undefined"
    ) {
      if (values instanceof Date) {
        formData.append(parentKey, values.toISOString());
      } else {
        formData.append(parentKey, values);
      }
    }
    // Arrayの場合はindexを、
    // オブジェクト({ key: value })の場合はkeyを付け加え、
    // makeFormDataを再帰的に呼び出す
    else if (Array.isArray(values)) {
      values.forEach((v, i) => {
        this.makeFormData(v, formData, `${parentKey}[${i}]`);
      });
    } else if (typeof values === "object" && values !== null) {
      Object.keys(values).forEach((k) => {
        this.makeFormData(values[k], formData, `${parentKey}[${k}]`);
      });
    }

    return formData;
  }
}

export default new ApiClient();

export class NotFoundError extends Error {
  constructor() {
    super("NotFound Error");
    Object.setPrototypeOf(this, NotFoundError.prototype);
  }
}
export class UnexpectedError extends Error {
  constructor() {
    super("Unexpected Error");
    Object.setPrototypeOf(this, UnexpectedError.prototype);
  }
}
export class UnauthorizedError extends Error {
  constructor() {
    super("Unauthorized Error");
    Object.setPrototypeOf(this, UnauthorizedError.prototype);
  }
}
export class UnprocessableEntityError extends Error {
  constructor() {
    super("UnprocessableEntity Error");
    Object.setPrototypeOf(this, UnprocessableEntityError.prototype);
  }
}
export class CourseOutOfDurationError extends Error {
  constructor() {
    super("CourseOutOfDuration Error");
    Object.setPrototypeOf(this, CourseOutOfDurationError.prototype);
  }
}

export type HTTPError =
  | NotFoundError
  | UnexpectedError
  | UnauthorizedError
  | UnprocessableEntityError
  | CourseOutOfDurationError;

export type PaginationMetaData = {
  currentPage: number;
  totalPages: number;
};
