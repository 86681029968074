import { forwardRef } from "react";
import styles from "./View.module.scss";
import clsx from "clsx";
import { Colors } from "../../helpers/colors";

type DivProps = JSX.IntrinsicElements["div"];
type ColorTypes = Extract<
  Colors,
  "white" | "color-gray-lighten-3" | "gray-lighten-4"
>;
export type ViewProps = DivProps & {
  colorType?: ColorTypes;
  fullWidth?: boolean;
  fullHeight?: boolean;
  shadow?: boolean;
};

export const View = forwardRef<HTMLDivElement, ViewProps>(function View(
  {
    children,
    colorType,
    className,
    fullHeight,
    shadow,
    fullWidth,
    ...htmlProps
  },
  ref,
) {
  const classNames = clsx(styles.root, className, {
    [styles.colorWhite]: colorType === "white",
    [styles.colorGrayLighten3]: colorType === "color-gray-lighten-3",
    [styles.colorGrayLighten4]: colorType === "gray-lighten-4",
    [styles.fullWidth]: fullWidth,
    [styles.fullHeight]: fullHeight,
    [styles.shadow]: shadow === true,
  });
  return (
    <div className={classNames} {...htmlProps} ref={ref}>
      {children}
    </div>
  );
});
