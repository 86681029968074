import reactStringReplace from "react-string-replace";
import clsx from "clsx";
import styles from "./DescriptionText.module.scss";
import { genId } from "../../helpers/Id";

type Props = {
  breakAll?: boolean;
  children: string;
  truncate?: boolean;
};
export const DescriptionText = (props: Props) => {
  const replacedWithLink = reactStringReplace(
    props.children,
    /(https?:\/\/[\w/:%#$&?()~.=+-]+)/g,
    (match) => {
      return (
        <a
          key={genId("description-text-anchor")}
          href={match}
          target="_blank"
          rel="noopener noreferrer"
        >
          {match}
        </a>
      );
    },
  );
  const replacedWithBr = reactStringReplace(
    replacedWithLink,
    /(\r\n|\r|\n)/g,
    () => <br key={genId("description-text-br")} />,
  );

  return (
    <p
      className={clsx({
        [styles.breakAll]: props.breakAll,
        [styles.truncate]: props.truncate,
      })}
    >
      {replacedWithBr}
    </p>
  );
};
