import { useRef, useEffect, useState } from "react";
import { useFlashMessageComponentState } from "./FlashMessageContext";
import { Success, Close } from "../Icon";
import clsx from "clsx";
import styles from "./FlashMessage.module.scss";
import { Flex } from "../Flex";
import { useSafeLayoutEffect } from "../../hooks/useSafeLayoutEffect";
import { useDomEventListener } from "../../hooks/useDomEventListener";
import { DescriptionText } from "../../components/DescriptionText";

// Main hooks
const useFlashMessage = () => {
  const { flashMessageState, closeMessage } = useFlashMessageComponentState();
  const domRef = useRef<HTMLDivElement | null>(null);
  const [visible, setVisible] = useState(
    () => flashMessageState.status !== "none",
  );

  useDomEventListener({
    eventName: "transitionend",
    onFire: closeMessage,
    element: domRef.current,
  });

  useEffect(() => {
    if (flashMessageState.status !== "none") {
      setVisible(true);
    }
  }, [flashMessageState.status]);

  useTriggerTimer({
    // flashMessageState.status を渡すとプリミティブ型は同じ値の更新が検知できないので、stateの参照をそのまま渡す
    dependencyValue: flashMessageState,
    shouldTriggerTimer: (val) => val.status !== "none",
    timeMs: 5000,
    onTimeout: () => {
      setVisible(false);
    },
  });

  const classNames = clsx(styles.FlashMessage, {
    [styles["FlashMessage--Success"]]: flashMessageState.status === "success",
    [styles["FlashMessage--Failed"]]: flashMessageState.status === "error",
    [styles["FlashMessage--Hidden"]]: !visible,
    [styles["FlashMessage--Visible"]]: visible,
  });

  const buttonProps = {
    onClick() {
      setVisible(false);
    },
  };

  const rootProps = {
    ref: domRef,
    className: classNames,
  };

  return {
    shouldRender: flashMessageState.status !== "none",
    message: flashMessageState.message,
    buttonProps,
    rootProps,
  };
};

// Main component
export const FlashMessage = () => {
  const { rootProps, buttonProps, shouldRender, message } = useFlashMessage();

  return (
    <div {...rootProps}>
      {shouldRender && (
        <button type="button" {...buttonProps}>
          <div className={styles.FlashMessage__CloseButton}>
            <Close colorType="white" />
          </div>
          <Flex alignItems="center" gap="1.4">
            <Success colorType="white" />
            <DescriptionText>{message ?? ""}</DescriptionText>
          </Flex>
        </button>
      )}
    </div>
  );
};

// helpers
type UseTriggerTimerProps<Value> = {
  dependencyValue: Value;
  shouldTriggerTimer: (val: Value) => boolean;
  timeMs: number;
  onTimeout: () => void;
};
const useTriggerTimer = <Value,>({
  dependencyValue,
  shouldTriggerTimer,
  timeMs,
  onTimeout,
}: UseTriggerTimerProps<Value>) => {
  // メモリリーク対策用フラグ
  const mountedRef = useRef(true);

  // コンポーネントアンマウント時にフラグをオフ
  useSafeLayoutEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const isTimerOnRef = useRef(false);
  const timerIDRef = useRef(-1);

  useSafeLayoutEffect(() => {
    const isTimerOn = shouldTriggerTimer(dependencyValue);
    // フラッシュメッセージが表示中に、再度表示をdispatchされた場合はタイマをリセットして測り直す
    if (isTimerOn) {
      window.clearTimeout(timerIDRef.current);
      timerIDRef.current = window.setTimeout(() => {
        // コンポーネントがマウント中の時のみコールバックを実行する
        if (mountedRef.current) {
          onTimeout();
        }
      }, timeMs);
    }
    // value が変更され、タイマーのトリガーがoffになったら現在のトリガーの状態と比較する
    // true -> false ならタイマークリア
    // false -> false なら何もしない
    if (!isTimerOn && isTimerOnRef.current === true) {
      window.clearTimeout(timerIDRef.current);
    }
    isTimerOnRef.current = isTimerOn;
  }, [dependencyValue]);
};
