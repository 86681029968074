import styles from "./HowtoLink.module.scss";
import { Colors } from "../../helpers/colors";
import classnames from "clsx";
import { IconHelp } from "@studyplus/boron-ui";

type HowtoLinkProps = {
  color?: Extract<Colors, "color-gray-darken-2" | "color-primary">;
  url: string;
};
export const HowToLink = ({
  color = "color-gray-darken-2",
  url,
}: HowtoLinkProps) => {
  return (
    <div className={styles.HowtoLinkWrapper}>
      <a
        className={styles.HowtoLink}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span
          className={classnames(styles.HowtoLink__text, {
            [styles["HowtoLink__text--primary"]]: color === "color-primary",
            [styles["HowtoLink__text--darkGray2"]]:
              color === "color-gray-darken-2",
          })}
        >
          使い方ガイド
        </span>
        <span
          className={classnames({
            [styles["HowtoLink__text--primary"]]: color === "color-primary",
            [styles["HowtoLink__text--darkGray2"]]:
              color === "color-gray-darken-2",
          })}
        >
          <IconHelp />
        </span>
      </a>
    </div>
  );
};
