import * as React from "react";
import styles from "./404.module.scss";

export const Error404 = () => {
  return (
    <>
      <p>下記のいずれかをお試しください。</p>
      <ul className={styles.posibilities}>
        <li className={styles.posibilitiesItem}>
          <div>
            閲覧可能でないアカウントでログインしている可能性がございます。
            <br />
            <a
              href={process.env.FLUORINE_URL}
              className={styles.linksToLogout}
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.studyplus.jp/
            </a>
            <a href="/" className={styles.linksToLogout} target="_blank">
              https://contents.studyplus.co.jp/
            </a>
            の双方を別のタブで開き、双方から一度ログアウトいただいた上で、再度お試しください。
          </div>
        </li>
        <li className={styles.posibilitiesItem}>
          <div>
            指定のコースが削除されたか、閲覧対象者の変更が行われた可能性がございます。
            <br />
            配信元の教室に学習コンテンツ配信の利用状況をご確認ください。
          </div>
        </li>
      </ul>
    </>
  );
};
