import Head from "next/head";
import { getPageTitle } from "../helpers/page";
import { View } from "../components/View/View";
import { BasicLayout } from "../components/Layout/Layout";
import { ErrorContent } from "../components/ErrorView";
import * as Sentry from "@sentry/react";

function UnexpectedErrorPage({ err }: any) {
  return (
    <BasicLayout>
      <UnexpectedErrorView err={err} />
    </BasicLayout>
  );
}
UnexpectedErrorPage.getInitialProps = ({ err }: any) => {
  return { err };
};

const errorTitle = "エラーが発生しています。";
export function UnexpectedErrorView({ err }: { err: Error | null }) {
  if (err) {
    Sentry.captureException(err);
  }

  return (
    <>
      <Head>
        <title>{getPageTitle(errorTitle)}</title>
      </Head>
      <View colorType="color-gray-lighten-3">
        <ErrorContent
          title={errorTitle}
          description="しばらく時間をおいてから再度お試しください。"
        />
      </View>
    </>
  );
}

export default UnexpectedErrorPage;
