import * as React from "react";

interface Props extends React.ClassAttributes<Record<string, never>> {
  children?: React.ReactNode;
}

const Label = (props: Props) => {
  return <>{props.children}</>;
};

export default Label;
