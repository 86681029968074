import styles from "./ErrorPage.module.scss";

type Props = {
  title: React.ReactNode;
  description: React.ReactNode;
};

export const ErrorContent = ({ title, description }: Props) => (
  <div className={styles.ErrorView}>
    <h2 className={styles.ErrorTitle}>{title}</h2>
    <div className={styles.ErrorView__Description}>
      <div className={styles.Description}>{description}</div>
    </div>
  </div>
);
