import { FlashMessage } from "./FlashMessage";
import {
  useInitFlashMessage,
  FlashMessageComponentStateProvider,
  FlashMessageDispatcherContextProvider,
} from "./FlashMessageContext";
type FlashMessageProviderProps = {
  children: React.ReactNode;
};
export const FlashMessageProvider = ({
  children,
}: FlashMessageProviderProps) => {
  const initialFlashMessage = useInitFlashMessage();
  return (
    <FlashMessageComponentStateProvider
      value={{
        flashMessageState: initialFlashMessage.flashMessageState,
        closeMessage: initialFlashMessage.closeMessage,
      }}
    >
      <FlashMessageDispatcherContextProvider
        value={{
          showErrorMessage: initialFlashMessage.showErrorMessage,
          showMessage: initialFlashMessage.showMessage,
        }}
      >
        <FlashMessage />
        {children}
      </FlashMessageDispatcherContextProvider>
    </FlashMessageComponentStateProvider>
  );
};
