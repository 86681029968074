import { useContext, useEffect } from "react";
import ReactGA4 from "react-ga4";
import { useRouter } from "next/router";
import { AuthenticationContext } from "../../AuthenticationProvider";

export const Tracker = () => {
  const router = useRouter();
  const context = useContext(AuthenticationContext);
  const username = context?.userProfile?.username;
  const trackPage = () => {
    if (
      process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID === undefined ||
      username == null
    ) {
      return;
    }
    const queryString = require("query-string");
    const query = queryString.stringify(router.query);

    ReactGA4.set({ userId: username });
    ReactGA4.send({
      hitType: "pageview",
      page: router.pathname + (query ? `?${query}` : ""),
    });
  };

  useEffect(() => {
    trackPage();
  }, [router.pathname, router.query, username]);

  return null;
};
