import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";

type ReturnInitShowMessageHooks = ReturnType<typeof useInitHeadMessage>;

// Context for dispatching message
type HeadMessageDispatcher =
  ReturnInitShowMessageHooks["dispatcherProviderValues"];

const HeadMessageDispatcherContext = createContext<
  HeadMessageDispatcher | undefined
>(undefined);

export const HeadMessageDispatcherContextProvider =
  HeadMessageDispatcherContext.Provider;

export const useHeadMessage = () => {
  const headMessageContext = useContext(HeadMessageDispatcherContext);
  if (!headMessageContext) {
    throw new Error(
      "useHeadMessage should be called from HeadMessageDispatcherProvider's children",
    );
  }
  return headMessageContext;
};

// Context for manipulating the message element
type HeadMessageComponentState =
  ReturnInitShowMessageHooks["stateProviderValues"];

const HeadMessageComponentStateContext = createContext<
  HeadMessageComponentState | undefined
>(undefined);

export const HeadMessageComponentStateProvider =
  HeadMessageComponentStateContext.Provider;

export const useHeadMessageComponentState = () => {
  const headMessageComponentStateContext = useContext(
    HeadMessageComponentStateContext,
  );
  if (!headMessageComponentStateContext) {
    throw new Error(
      "useHeadMessage should be called from HeadMessageComponentProvider's children",
    );
  }
  return headMessageComponentStateContext;
};

// Root state
export type MessageStatus = "none" | "attention";

type HeadMessageState = {
  status: MessageStatus;
  message?: string | JSX.Element;
};

const InitialHeadMessageState: HeadMessageState = {
  status: "none",
};

export const useInitHeadMessage = () => {
  const [headMessageState, setHeadMessageState] = useState(
    InitialHeadMessageState,
  );

  const showAttentionMessage = useCallback((message: string | JSX.Element) => {
    setHeadMessageState({ status: "attention", message });
  }, []);

  const closeMessage = useCallback(() => {
    setHeadMessageState({ status: "none" });
  }, []);

  const stateProviderValues = useMemo(() => {
    return { headMessageState };
  }, [headMessageState]);

  const dispatcherProviderValues = useMemo(() => {
    return { showAttentionMessage, closeMessage };
  }, []);

  return {
    stateProviderValues,
    dispatcherProviderValues,
  };
};
