import { AppProps } from "next/app";
import React from "react";
import "../styles/globals.scss";
import "normalize.css";
import "../styles/tailwind.css";
import "@studyplus/boron-ui/dist/boron-ui.css";
import { ReactQueryDevtools } from "react-query-devtools";
import { QueryClientProvider, QueryClient } from "react-query";
import { FlashMessageProvider } from "../components/FlashMessage";
import { initSentry } from "../helpers/sentry";
import { AuthenticationProvider } from "../components/AuthenticationProvider";
import { HeadMessageProvider } from "../components/HeadMessage";
import { Tracker } from "../components/GoogleAnalytics/Tracker";
import ReactGA4 from "react-ga4";

initSentry();

if (typeof window !== "undefined") {
  Object.assign(window, {
    environments: {
      release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    },
  });
}

function MyApp({ Component, pageProps }: AppProps) {
  const queryClientRef = React.useRef<QueryClient | null>(null);
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient();
  }

  if (process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID) {
    ReactGA4.initialize(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID);
  }

  return (
    <>
      <QueryClientProvider client={queryClientRef.current}>
        <FlashMessageProvider>
          <HeadMessageProvider>
            <AuthenticationProvider>
              <Component {...pageProps} />
              <Tracker />
            </AuthenticationProvider>
          </HeadMessageProvider>
        </FlashMessageProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
