import {
  Dispatch,
  PropsWithChildren,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { UserProfile } from "../../domains/UserProfile";
import { useUserProfileApi } from "../../hooks/api/useUserProfileApi";
import { ErrorView } from "../ErrorView";
import { UnauthorizedError } from "../../helpers/api";
import { useSignOutApi } from "../../hooks/api/useSignOutApi";
import { useFlashMessage } from "../FlashMessage";
import {
  AuthenticationContext,
  AuthenticationStatus,
  makeAuthenticationContext,
} from "./AuthenticationContext";
import * as Sentry from "@sentry/react";

type State = {
  userProfile: UserProfile | null;
  status: AuthenticationStatus;
};
type Actions =
  | {
      type: "START_AUTHENTICATION";
    }
  | {
      type: "COMPLETE_AUTHENTICATION";
      userProfile: UserProfile;
    }
  | {
      type: "UNAUTHENTICATED";
    }
  | {
      type: "COMPLETE_SIGN_OUT";
    };

const authenticationReducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case "START_AUTHENTICATION": {
      return { ...state, status: "authenticating" };
    }
    case "COMPLETE_AUTHENTICATION": {
      const { userProfile } = action;
      return { ...state, userProfile, status: "authenticated" };
    }
    case "UNAUTHENTICATED": {
      return { ...state, userProfile: null, status: "unauthenticated" };
    }
    case "COMPLETE_SIGN_OUT": {
      return { ...state, userProfile: null, status: "unauthenticated" };
    }
  }
};

type Props = PropsWithChildren<Record<string, unknown>>;
export const AuthenticationProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(authenticationReducer, {
    userProfile: null,
    status: "initialized",
  });

  const {
    userProfile,
    isLoading: isLoadingUserProfile,
    error,
  } = useUserProfileApi();

  // 認証関連処理
  useEffect(() => {
    if (isLoadingUserProfile) {
      dispatch({ type: "START_AUTHENTICATION" });
    } else if (userProfile) {
      dispatch({ type: "COMPLETE_AUTHENTICATION", userProfile: userProfile });
    }
  }, [isLoadingUserProfile, userProfile]);
  useEffect(() => {
    if (error instanceof UnauthorizedError) {
      dispatch({ type: "UNAUTHENTICATED" });
    }
  }, [error]);
  useEffect(() => {
    Sentry.setUser({ id: userProfile?.username });
  }, [userProfile]);

  const signOut = useSignOut(state, dispatch);

  const context = useMemo(() => {
    return makeAuthenticationContext({
      userProfile: state.userProfile,
      status: state.status,
      signOut,
    });
  }, [state.userProfile, state.status, signOut]);

  if (error && !(error instanceof UnauthorizedError)) {
    return <ErrorView error={error} />;
  }

  return (
    <AuthenticationContext.Provider value={context}>
      {children}
    </AuthenticationContext.Provider>
  );
};

const useSignOut = (state: State, dispatch: Dispatch<Actions>) => {
  // サインアウト関連処理
  const { showMessage, showErrorMessage } = useFlashMessage();

  const { mutate: signOut } = useSignOutApi({
    onSuccess: () => {
      dispatch({ type: "COMPLETE_SIGN_OUT" });
      showMessage("ログアウトしました");
    },
    onError: () => {
      showErrorMessage("ログアウトできませんでした");
    },
  });

  return signOut;
};
