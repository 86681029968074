import * as Sentry from "@sentry/react";

// クライアント側はboron-studentのURLを許可するが、サーバー側はboron-studentのURLを取れないので一旦全てのURLを許可しておきます
// NOTE: @sentry/next を使うようになったら、configがファイルごと分かれるのでこのような分岐は不要になりそうです
const allowUrls =
  typeof window !== "undefined" ? window.location.origin : new RegExp(".*");
const gaUrl = "https://www.google-analytics.com/g/collect";

export const initSentry = () => {
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.SENTRY_DSN,
    release: process.env.COMMIT_SHA,
    environment: process.env.COMMIT_REF,
    ignoreErrors: [
      "TypeError: window.onorientationchange is not a function.",
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
      "ReferenceError: Can't find variable:",
      "onSvFinishLoading is not defined",
      "AbortController is not defined",
      "Unexpected end of script",
      "Cancel rendering route",
      "Non-Error promise rejection captured with keys",
      "Non-Error promise rejection captured with value",
      // google translate extension由来と思われるエラーを回避
      // 部分一致なのでevaluating 'a.fa'やevaluating 'a.h'などこれまで出たことのあるエラーを回避することを期待している
      "undefined is not an object (evaluating 'a.",
      "undefined is not an object (evaluating 'window.webkit.messageHandlers')",
    ],
    allowUrls: [allowUrls],
    beforeSend(event) {
      const exception = event.exception;

      if (exception && exception.values) {
        if (exception.values[0].mechanism) {
          const mechanism = exception.values[0].mechanism;
          if (
            exception.values[0].type == "TypeError" &&
            mechanism.type == "generic" &&
            mechanism.handled
          )
            return null;
        }

        // TypeError: Load failedのエラーか確認する
        if (
          exception.values[0].type === "TypeError" &&
          exception.values[0].value === "Load failed"
        ) {
          // google-analyticsにリクエストされているか確認
          const breadcrumbs = event.breadcrumbs;
          if (breadcrumbs) {
            const hasGoogleAnalyticsError = breadcrumbs.some(
              (breadcrumb) =>
                breadcrumb.type === "http" &&
                breadcrumb.level === "error" &&
                breadcrumb.data?.url.startsWith(gaUrl),
            );

            if (hasGoogleAnalyticsError) {
              return null;
            }
          }
        }
      }

      return event;
    },
  });
};
