import {
  CourseOutOfDurationError,
  NotFoundError,
  UnauthorizedError,
} from "../../helpers/api";
import UnauthorizedErrorView from "../../pages/401";
import { NotFoundErrorView } from "../../pages/404";
import { CourseOutOfDurationErrorView } from "../../pages/CourseOutOfDurationError";
import { UnexpectedErrorView } from "../../pages/_error";

type Props = {
  error: Error | null;
};

export const ErrorView = ({ error }: Props) => {
  if (error === null) {
    return null;
  }

  if (error instanceof CourseOutOfDurationError) {
    return <CourseOutOfDurationErrorView />;
  }

  if (error instanceof NotFoundError) {
    return <NotFoundErrorView />;
  }

  if (error instanceof UnauthorizedError) {
    return <UnauthorizedErrorView />;
  }

  return <UnexpectedErrorView err={error} />;
};
