import classNames from "clsx";
import * as React from "react";

import styles from "./styles.module.scss";

interface Props extends React.Attributes {
  children: React.ReactNode;
  shouldCloseOnClick?: boolean;
}

interface State {
  listVisible: boolean;
}

class Container extends React.Component<Props, State> {
  static defaultProps = {
    shouldCloseOnClick: true,
  };
  ref: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: Props) {
    super(props);
    this.state = {
      listVisible: false,
    };
  }

  render(): React.ReactNode {
    return (
      <>
        {this.state.listVisible ? (
          <div className={styles.backdrop} onClick={this.closeList} />
        ) : null}
        <div
          className={classNames(styles.container, {
            [styles.listVisible]: this.state.listVisible,
          })}
          onClick={this.handleClick}
          ref={this.ref}
        >
          {this.props.children}
        </div>
      </>
    );
  }

  private closeList = (e: any) => {
    if (this.ref.current && this.ref.current.contains(e.target)) {
      return;
    }

    if (this.state.listVisible) {
      this.setState({
        listVisible: false,
      });
    }
  };

  private handleClick = () => {
    if (this.props.shouldCloseOnClick) {
      this.setState({
        listVisible: !this.state.listVisible,
      });
    } else {
      this.setState({
        listVisible: true,
      });
    }
  };
}

export default Container;
