import { useHeadMessageComponentState } from "./HeadMessageContext";
import { AttentionFilled } from "../Icon";
import clsx from "clsx";
import styles from "./HeadMessage.module.scss";
import { Flex } from "../Flex";

// Main hooks
const useHeadMessage = () => {
  const { headMessageState } = useHeadMessageComponentState();

  const classNames = clsx(styles.HeadMessage, {
    [styles["HeadMessage--Attention"]]: headMessageState.status === "attention",
  });

  const rootProps = {
    className: classNames,
  };

  return {
    shouldRender: headMessageState.status !== "none",
    message: headMessageState.message,
    rootProps,
  };
};

// Main component
export const HeadMessage = () => {
  const { rootProps, shouldRender, message } = useHeadMessage();

  return shouldRender ? (
    <div {...rootProps}>
      <Flex justifyContent="center" alignItems="center" gap="1.4">
        <div className={styles.iconContainer}>
          <AttentionFilled colorType="color-primary" />
        </div>
        <div>{message ?? ""}</div>
      </Flex>
    </div>
  ) : null;
};
