import styles from "./IconHoverWrapper.module.scss";
import classNames from "clsx";
import { Colors } from "../../helpers/colors";

type Props = {
  children: React.ReactNode;
  colorType?: Extract<Colors, "color-primary" | "color-primary-darken">;
};

export const IconHoverGroup: React.FC<Props> = ({
  children,
  colorType = "color-primary",
}) => {
  return (
    <div
      className={classNames({
        // colorTYpe
        [styles.primary]: colorType === "color-primary",
        [styles.primaryDarken]: colorType === "color-primary-darken",
      })}
    >
      {children}
    </div>
  );
};
