import styles from "./Flex.module.scss";
import clsx from "clsx";

type Props = {
  alignItems?: "center" | "baseline";
  justifyContent?:
    | "start"
    | "end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | "stretch"
    | "baseline";
  marginTop?: MarginRules;
  gap?: MarginRules;
  flexGap?: MarginRules;
  direction?: "row" | "column";
  wrap?: boolean;
  children?: React.ReactNode;
};

type MarginRules =
  | "0.4"
  | "0.8"
  | "1.0"
  | "1.4"
  | "1.6"
  | "2.0"
  | "2.2"
  | "3.2";

export const Flex: React.FC<Props> = ({
  marginTop,
  alignItems,
  justifyContent,
  children,
  direction = "row",
  gap,
  flexGap,
  wrap,
}) => {
  const classNames = clsx(
    styles.Flex,
    determineGap(direction, gap),
    determineJustifyContent(justifyContent),
    determineMarginTop(marginTop),
    determineFlexGap(flexGap),
    {
      [styles.directionColumn]: direction === "column",
      [styles.alignItemsCenter]: alignItems === "center",
      [styles.alignItemsBaseline]: alignItems === "baseline",
      [styles.wrap]: wrap,
    },
  );
  return <div className={classNames}>{children}</div>;
};

const determineJustifyContent = (justifyContent: Props["justifyContent"]) => {
  if (!justifyContent) {
    return {};
  }
  const array = justifyContent.split("-");
  const key = array
    .map((el) => `${el.charAt(0).toUpperCase()}${el.slice(1)}`)
    .join("");
  return styles[`justifyContent${key}`];
};

const determineGap = (
  direction: "row" | "column",
  marginSize?: MarginRules,
) => {
  if (!marginSize) {
    return null;
  }
  const vOrH = direction === "column" ? "vertical" : "horizontal";
  return styles[`gap_${vOrH}_${Number(marginSize) * 10}`];
};

const determineMarginTop = (marginTop?: MarginRules) => {
  if (!marginTop) {
    return null;
  }
  return styles[`marginTop_${Number(marginTop) * 10}`];
};

const determineFlexGap = (flexGap?: MarginRules) =>
  flexGap ? styles[`flexGap_${Number(flexGap) * 10}`] : null;
