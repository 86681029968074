import { createContext, useContext, useState, useCallback } from "react";

type ReturnInitShowMessageHooks = ReturnType<typeof useInitFlashMessage>;

// Contenxt for dispatching message
type FlashMessageDispatcher = Pick<
  ReturnInitShowMessageHooks,
  "showErrorMessage" | "showMessage"
>;

const FlashMessageDispatcherContext = createContext<
  FlashMessageDispatcher | undefined
>(undefined);

export const FlashMessageDispatcherContextProvider =
  FlashMessageDispatcherContext.Provider;

export const useFlashMessage = () => {
  const flashMessageContext = useContext(FlashMessageDispatcherContext);
  if (!flashMessageContext) {
    throw new Error(
      "useFlashMessage should be called from FlashMessageDispatcherProvder's children",
    );
  }
  return flashMessageContext;
};

// Context for manipulating the message element
type FlashMessageComponentState = Pick<
  ReturnInitShowMessageHooks,
  "flashMessageState" | "closeMessage"
>;
const FlashMessageComponentStateContext = createContext<
  FlashMessageComponentState | undefined
>(undefined);

export const FlashMessageComponentStateProvider =
  FlashMessageComponentStateContext.Provider;

export const useFlashMessageComponentState = () => {
  const flashMessageComponentStateContext = useContext(
    FlashMessageComponentStateContext,
  );
  if (!flashMessageComponentStateContext) {
    throw new Error(
      "useFlashMessage should be called from FlashMessageComponentProvder's children",
    );
  }
  return flashMessageComponentStateContext;
};

// Root state
export type MessageStatus = "none" | "error" | "success";

type FlashMessageState = {
  status: MessageStatus;
  message?: string;
};

const InitialFlashMessageState: FlashMessageState = {
  status: "none",
};

export const useInitFlashMessage = () => {
  const [flashMessageState, setFlashMessageState] = useState(
    InitialFlashMessageState,
  );

  const showErrorMessage = useCallback((message: string) => {
    setFlashMessageState({ status: "error", message });
  }, []);

  const showMessage = useCallback((message: string) => {
    setFlashMessageState({ status: "success", message });
  }, []);

  const closeMessage = useCallback(() => {
    setFlashMessageState({ status: "none" });
  }, []);

  return {
    showErrorMessage,
    showMessage,
    flashMessageState,
    closeMessage,
  };
};
