import { PropsWithChildren } from "react";
import Head from "next/head";
import { Header } from "./Header";
import { Footer } from "./Footer";
import styles from "./Layout.module.scss";

export const Layout = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <>
      <Head>
        <meta
          content="width=device-width,initial-scale=1.0,user-scalable=no"
          name="viewport"
        />
      </Head>
      {children}
    </>
  );
};

export const BasicLayout = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <Layout>
      <Header />
      <Content>{children}</Content>
      <Footer />
    </Layout>
  );
};

export const Content = ({ children }: PropsWithChildren<unknown>) => {
  return <div className={styles.Content}>{children}</div>;
};
