import { ViewProps, View } from "../View";
import styles from "./Header.module.scss";
import { ArrowDropDown, NaviAvatar } from "../Icon";
import * as React from "react";
import Dropdown from "../Dropdown";
import { IconButton } from "../IconButton";
import { useAuthentication } from "../AuthenticationProvider/useAuthentication";
import classNames from "clsx";
import { HowToLink } from "./HowtoLink";
import Link from "next/link";
import { IconExternalLink } from "@studyplus/boron-ui";

const HOW_TO_URL = "https://fs-help.studyplus.co.jp/ja/articles/4964877";

type Props = {
  borderBottom?: boolean;
};
export const Header = ({ borderBottom }: Props) => {
  const { isSignedIn, signOut } = useAuthentication();
  return (
    <header
      className={classNames(styles.Header, { [styles.border]: borderBottom })}
    >
      <View fullHeight colorType="white">
        <div className={styles.Header__content}>
          <Link href="/">
            <a className={styles.Logo}>
              <img
                className={styles.Header__logo}
                src="/assets/images/studyplus_logo_color.png"
                alt="Studyplus logo"
              />
            </a>
          </Link>
          <div className={styles.Header__right}>
            {isSignedIn ? (
              <AccountDropdown signOut={signOut} />
            ) : (
              <HowToLink url={HOW_TO_URL} />
            )}
          </div>
        </div>
      </View>
    </header>
  );
};

type BaseHeaderProps = {
  colorType: ViewProps["colorType"];
};
export const BaseHeader = ({
  colorType,
  children,
}: React.PropsWithChildren<BaseHeaderProps>) => (
  <header className={styles.Header}>
    <View fullHeight colorType={colorType}>
      <div
        className={classNames(
          styles.Header__content,
          styles["Header__content--space-between"],
          styles["Header__content--center"],
        )}
      >
        {children}
      </div>
    </View>
  </header>
);

type AccountDropdownProps = {
  signOut: ReturnType<typeof useAuthentication>["signOut"];
};
const AccountDropdown = ({ signOut }: AccountDropdownProps) => {
  return (
    <Dropdown.Container>
      <Dropdown.Label>
        <IconButton hoverColorType="color-primary">
          <NaviAvatar colorType="color-gray-darken-1_5" size="lg" />
          <div className={styles.AccountDropdown__arrow}>
            <ArrowDropDown colorType="color-gray-darken-1_5" />
          </div>
        </IconButton>
      </Dropdown.Label>
      <Dropdown.List position={{ top: "40px", right: "0px" }}>
        <ul className={styles.AccountDropdown__list} role="menu">
          <Dropdown.Item href="/profile">
            <Link href="/profile">
              <a href="/profile" className={styles.profileLink}>
                アカウント
              </a>
            </Link>
          </Dropdown.Item>
          <Dropdown.Item
            className={styles.AccountDropdown__listItem}
            href={HOW_TO_URL}
            target="_blank"
            rel="noopener noreferrer"
            withBorder
          >
            <div className={styles.AccountDropdown__howtoLink}>
              <span className={styles.AccountDropdown__howto}>
                使い方ガイド
              </span>
              <IconExternalLink className="text-sm text-black" />
            </div>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => signOut()}>ログアウト</Dropdown.Item>
        </ul>
      </Dropdown.List>
    </Dropdown.Container>
  );
};
