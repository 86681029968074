import Head from "next/head";
import { getPageTitle } from "../helpers/page";
import { View } from "../components/View/View";
import { BasicLayout } from "../components/Layout/Layout";
import { ErrorContent } from "../components/ErrorView";
import { Error404 } from "../features/NotFoundError";

// TODO: 404エラーコーディング
const errorTitle = "ご指定のURLは無効になっています。";

function NotFoundErrorPage() {
  return (
    <BasicLayout>
      <NotFoundErrorView />
    </BasicLayout>
  );
}

export function NotFoundErrorView() {
  return (
    <>
      <Head>
        <title>{getPageTitle(errorTitle)}</title>
      </Head>
      <View colorType="color-gray-lighten-3">
        <ErrorContent title={errorTitle} description={<Error404 />} />
      </View>
    </>
  );
}

export default NotFoundErrorPage;
