import * as React from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";

interface Props extends React.ClassAttributes<Record<string, never>> {
  className?: string;
  href?: string;
  target?: string;
  rel?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  withBorder?: boolean;
  children?: React.ReactNode;
}

const Item = (props: Props) => {
  const rootClassName = clsx(styles.item, props.className, {
    [styles.withBorder]: props.withBorder,
  });
  return (
    <li className={rootClassName} role="none">
      {props.href ? (
        <a
          href={props.href}
          target={props.target}
          rel={props.rel}
          className={styles.item__link}
          role="menuitem"
        >
          {props.children}
        </a>
      ) : (
        <button
          className={styles.item__link}
          onClick={handleClick(props)}
          role="menuitem"
        >
          {props.children}
        </button>
      )}
    </li>
  );
};

const handleClick = (props: Props) => (e: React.SyntheticEvent) => {
  e.preventDefault();

  if (props.onClick) {
    props.onClick(e);
  }
};

export default Item;
