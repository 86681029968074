import { UserProfile } from "../../domains/UserProfile";
import { createContext } from "react";

export type AuthenticationStatus =
  | "initialized" // 初期状態（すぐ後にauthorizingになる）
  | "authenticating" // 認証APIにリクエストを投げて認証状態を確認中
  | "authenticated" // user情報が返ってきて認証できた
  | "unauthenticated"; // リクエストが失敗して認証できなかった or サインアウト済み
type AuthenticationContext = {
  userProfile: UserProfile | null;
  status: AuthenticationStatus;
  signOut: () => void;
  isLoaded: boolean;
  isSignedIn: boolean;
};
export const AuthenticationContext = createContext<
  AuthenticationContext | undefined
>(undefined);

type Params = Pick<AuthenticationContext, "userProfile" | "status" | "signOut">;
export const makeAuthenticationContext = (
  params: Params,
): AuthenticationContext => {
  const isLoaded =
    params.status === "authenticated" || params.status === "unauthenticated";
  const isSignedIn = params.status === "authenticated";

  return {
    ...params,
    isLoaded,
    isSignedIn,
  };
};
