import { useRouter } from "next/router";

const UnauthorizedErrorView = () => {
  const router = useRouter();
  if (typeof window !== "undefined") {
    const loginURL = new URL("/auth/student", process.env.BORON_URL);
    loginURL.search = new URLSearchParams({
      redirect_uri: window.location.toString(),
      fallback_uri: new URL(
        "/access_error",
        window.location.toString(),
      ).toString(),
      ...expiresAt(),
    }).toString();
    router.push(loginURL);
  }
  return null;
};

// NOTE: 生徒ログインの直後に入退室記録をつける遷移パターンでのみ必要なパラメータを設定します
//       boron側の生徒認証時(POST /auth/student)の有効期限チェックに使用します
const expiresAt = (): { expires_at: string } | undefined => {
  if (/\/enter_exit\/[^/]+$/.test(window.location.toString())) {
    // 5分の有効期限で制限(1回限りの利用に制限できるのが理想だが、想定外の時間経過後(数時間や数日後など)に入退室記録がついてしまうことを防ぐ)
    // c.f. https://studyplus.esa.io/posts/18845#%E5%AF%BE%E5%BF%9C%E5%86%85%E5%AE%B9
    const expiresInMinutes = 5;
    const now = new Date();
    const expiresAt = new Date(now);
    expiresAt.setMinutes(now.getMinutes() + expiresInMinutes);
    return { expires_at: expiresAt.toISOString() };
  }
};

export default UnauthorizedErrorView;
