import ApiClient, {
  UnauthorizedError,
  UnexpectedError,
} from "../../helpers/api";
import { useQuery } from "react-query";
import { UserProfile } from "../../domains/UserProfile";

type UseUserProfileError = UnexpectedError | UnauthorizedError;
type UseUserProfileApi = {
  userProfile: UserProfile | null;
  isLoading: boolean;
  error: UseUserProfileError | null;
};
export const useUserProfileApi = (): UseUserProfileApi => {
  const path = `/api/contents/me`;
  const result = useQuery<UserProfile, UseUserProfileError>(
    path,
    async () => {
      const response = await ApiClient.get(path);
      if (response.ok) {
        const json = await response.json();
        return json.steakUser as UserProfile;
      }
      if (response.status === 401) {
        throw new UnauthorizedError();
      }
      throw new UnexpectedError();
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
  return {
    userProfile: result.data ?? null,
    isLoading: result.isLoading,
    error: result.error,
  };
};
