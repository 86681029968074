import { useContext } from "react";
import { AuthenticationContext } from "./index";

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext);

  if (typeof context === "undefined") {
    throw new Error(
      "useAuthentication must be used within AuthenticationProvider",
    );
  }

  return context;
};
