import { ButtonHTMLAttributes, forwardRef } from "react";
import styles from "./IconButton.module.scss";
import clsx from "clsx";
import { IconHoverGroup } from "../IconHoverGroup";
import { Colors } from "../../helpers/colors";

type Variants = "transparent" | "solid";

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  variants?: Variants;
  hoverColorType?: Extract<Colors, "color-primary" | "color-primary-darken">;
  children: React.ReactNode; // 子要素(Icon)必須
};

export const IconButton = forwardRef<HTMLButtonElement, Props>(
  function IconButton(
    {
      variants = "transparent",
      hoverColorType = "color-primary",
      children,
      ...htmlProps
    },
    ref,
  ) {
    const classNames = clsx(styles.IconButton, {
      [styles.transparent]: variants === "transparent",
    });

    return (
      <IconHoverGroup colorType={hoverColorType}>
        <button className={classNames} ref={ref} {...htmlProps}>
          {children}
        </button>
      </IconHoverGroup>
    );
  },
);
