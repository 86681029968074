import { useLayoutEffect, useEffect } from "react";

export const useSafeLayoutEffect = canUseDOM() ? useLayoutEffect : useEffect;

function canUseDOM() {
  return !!(
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
  );
}
