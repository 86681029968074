import ArrowRightIcon from "./svg/icon-arrow-right.svg";
import ArrowLeftIcon from "./svg/icon-arrow-left.svg";
import ArrowUpIcon from "./svg/icon-arrow-up.svg";
import ArrowDownIcon from "./svg/icon-arrow-down.svg";
import TimerIcon from "./svg/icon-timer.svg";
import EllipsisIcon from "./svg/icon-ellipsis.svg";
import TimerPlayIcon from "./svg/icon-timer-play.svg";
import TimerPauseIcon from "./svg/icon-timer-pause.svg";
import VideoIcon from "./svg/icon-video.svg";
import TextBookIcon from "./svg/icon-textbook.svg";
import SyncIcon from "./svg/icon-sync.svg";
import CloseIcon from "./svg/icon-close-x.svg";
import FailedIcon from "./svg/icon-failed.svg";
import SuccessIcon from "./svg/icon-success.svg";
import CameraIcon from "./svg/icon-camera.svg";
import ItemRemoveIcon from "./svg/icon-item-remove.svg";
import NaviAvatarIcon from "./svg/icon-navi-avatar.svg";
import ArrowDropDownIcon from "./svg/icon-arrow-drop-down.svg";
import SchoolIcon from "./svg/icon-school.svg";
import AttentionIcon from "./svg/icon-attention.svg";
import AttentionFilledIcon from "./svg/icon-attention-filled.svg";
import CheckIcon from "./svg/icon-check.svg";
import FolderLevel1Icon from "./svg/icon-folder-level-1.svg";
import FolderLevel2Icon from "./svg/icon-folder-level-2.svg";
import QRCodeIcon from "./svg/icon-qrcode.svg";
import WarningIcon from "./svg/icon-warning.svg";
import { Colors } from "../../helpers/colors";
import clsx from "clsx";
import styles from "./Icon.module.scss";
import { ReactElement } from "react";

type Props = {
  // 全色使えるべきなのですが、方法模索中なので、必要な色は適宜足してください m(__)m
  colorType?: Extract<
    Colors,
    | "color-gray-darken-2"
    | "color-gray-darken-1_5"
    | "color-gray-darken-1"
    | "color-primary"
    | "white"
    | "color-green"
    | "color-black"
    | "color-red"
    | "color-notification"
  >;
  size?: "sm" | "ms" | "md" | "lg" | "xl";
  hidden?: boolean;
};
const createIconComponent = (comp: () => ReactElement) =>
  function createIconComponent(props: Props) {
    const className = clsx(styles.root, {
      [styles.colorGrayDarken2]: props.colorType === "color-gray-darken-2",
      [styles.colorGrayDarken1_5]: props.colorType === "color-gray-darken-1_5",
      [styles.colorGrayDarken1]: props.colorType === "color-gray-darken-1",
      [styles.colorPrimary]: props.colorType === "color-primary",
      [styles.colorGreen]: props.colorType === "color-green",
      [styles.colorWhite]: props.colorType === "white",
      [styles.colorBlack]: props.colorType === "color-black",
      [styles.colorRed]: props.colorType === "color-red",
      [styles.colorNotification]: props.colorType === "color-notification",
      [styles.sizeSmall]: props.size === "sm",
      [styles.sizeMediumSmall]: props.size === "ms",
      [styles.sizeMedium]:
        props.size === "md" || typeof props.size === "undefined",
      [styles.sizeLarge]: props.size === "lg",
      [styles.sizeXLarge]: props.size === "xl",
    });
    return (
      <div className={className} hidden={props.hidden}>
        {comp()}
      </div>
    );
  };

export const ArrowDown = createIconComponent(ArrowDownIcon);
export const ArrowUp = createIconComponent(ArrowUpIcon);
export const ArrowRight = createIconComponent(ArrowRightIcon);
export const Timer = createIconComponent(TimerIcon);
export const Ellipsis = createIconComponent(EllipsisIcon);
export const TimerPlay = createIconComponent(TimerPlayIcon);
export const TimerPause = createIconComponent(TimerPauseIcon);
export const Video = createIconComponent(VideoIcon);
export const Textbook = createIconComponent(TextBookIcon);
export const Sync = createIconComponent(SyncIcon);
export const Close = createIconComponent(CloseIcon);
export const Failed = createIconComponent(FailedIcon);
export const Success = createIconComponent(SuccessIcon);
export const Camera = createIconComponent(CameraIcon);
export const ItemRemove = createIconComponent(ItemRemoveIcon);
export const School = createIconComponent(SchoolIcon);
export const Attention = createIconComponent(AttentionIcon);
export const AttentionFilled = createIconComponent(AttentionFilledIcon);
export const NaviAvatar = createIconComponent(NaviAvatarIcon);
export const ArrowDropDown = createIconComponent(ArrowDropDownIcon);
export const ArrowLeft = createIconComponent(ArrowLeftIcon);
export const Check = createIconComponent(CheckIcon);
export const FolderLevel1 = createIconComponent(FolderLevel1Icon);
export const FolderLevel2 = createIconComponent(FolderLevel2Icon);
export const QRCode = createIconComponent(QRCodeIcon);
export const Warning = createIconComponent(WarningIcon);
