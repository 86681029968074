import ApiClient, { UnexpectedError } from "../../helpers/api";
import { useMutation } from "react-query";

type UseSignOutApi = {
  onSuccess?: () => void;
  onError?: () => void;
};
export const useSignOutApi = (options?: UseSignOutApi) => {
  return useMutation<void, UnexpectedError>(
    async () => {
      const res = await ApiClient.delete("/api/contents/sign_out");
      if (res.ok) {
        return;
      }
      throw new UnexpectedError();
    },
    {
      onSuccess: options?.onSuccess,
      onError: options?.onError,
    },
  );
};
