import { HeadMessage } from "./HeadMessage";
import {
  useInitHeadMessage,
  HeadMessageComponentStateProvider,
  HeadMessageDispatcherContextProvider,
} from "./HeadMessageContext";
import { PropsWithChildren } from "react";

export const HeadMessageProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const { stateProviderValues, dispatcherProviderValues } =
    useInitHeadMessage();

  return (
    <HeadMessageComponentStateProvider value={stateProviderValues}>
      <HeadMessageDispatcherContextProvider value={dispatcherProviderValues}>
        <HeadMessage />
        {children}
      </HeadMessageDispatcherContextProvider>
    </HeadMessageComponentStateProvider>
  );
};
